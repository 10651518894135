<template>
  <div>
    <b-card :title="greetingText + ', John!'" :sub-title="daytimeText">
      <code>
        {{ getAllPermissions() }}
      </code>
    </b-card>
  </div>
</template>


<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },

  data() {
    return {
      greetingText: "",
      daytimeText: "",
      heroImg: require("@/assets/images/svg/learning_sketching.svg"),
    };
  },

  mounted() {
    this.greeting();
    this.daytimeNow();
  },

  methods: {
    greeting() {
      const date = new Date();
      const hour = date.getHours();
      if (hour < 12) {
        this.greetingText = "Good Morning";
      } else if (hour < 18) {
        this.greetingText = "Good Afternoon";
      } else {
        this.greetingText = "Good Evening";
      }
    },

    daytimeNow() {
      // return like this It’s Tuesday, 14 February
      const date = new Date();
      const weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      const n = weekday[date.getDay()];
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // convert month number to month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[month - 1];

      this.daytimeText = `It's ${n}, ${day} ${monthName} ${year}`;
    },
  },
};
</script>
